import React, { useEffect, useState } from 'react';
import loaderServer from "../../assets/images/loader-server.png";
import "./style.css";
// import { saveProctoringData } from '../../redux/actions/quiz';
import { useDispatch } from 'react-redux';
import JSZip from 'jszip';
import InScreenLoader from '../../components/InScreenLoader';
import { useLocation, useNavigate } from 'react-router-dom';
import { getBrowserInfo } from '../../helper';
import { CloudFileUpload, createPresignedURL } from '../../features/cloudSlice';
import { submitProctoringData } from '../../features/quizSlice';
import Base from '../../components/Layout/Base';

const QuizUpload = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const kidId = location?.state?.kidId;
  const eventId = location?.state?.eventId;
  const acm_quiz_id = location?.state?.acm_quiz_id;
  const attempt_id = location?.state?.attempt_id;
  const tab_switch_count = location?.state?.tab_switch_count;
  const full_screen_exit_count = location?.state?.full_screen_exit_count;
  const background_pop_up_count = location?.state?.background_pop_up_count;
  const capturedVideo = location?.state?.capturedVideoZip;
  const capturedImages = location?.state?.capturedImagesZip;
  const imageSrc = location?.state?.imageSrc;

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const uploadFile = (file, fileType, type, name) => {
      return new Promise(async (resolve, reject) => {
        if (file) {
          const resultAction = await dispatch(createPresignedURL({ file_type: fileType, upload_type: "quiz" })).unwrap();
          const uploadResponse = await dispatch(CloudFileUpload({ url: resultAction?.url, file, type })).unwrap();
          const fileUrl = uploadResponse?.url?.split("?")?.[0]
          resolve({ name, fileUrl })
          // dispatch(createPresignedURL({ file_type: fileType, upload_type: "quiz" }, (url) => {
          //   dispatch(CloudFileUpload({ url, file, type }, (fileUrl) => {
          //     resolve({ name, fileUrl });
          //   }));
          // }));
        } else {
          resolve(null);  // Resolve immediately if file is undefined
        }
      });
    };

    const uploadFiles = async () => {
      try {

        const imageBlob = await fetch(imageSrc).then(res => res.blob());
        const uploadPromises = [uploadFile(imageBlob, "Image", "image", "imagesBeforeUrl")];

        if (capturedVideo) {
          const videoZip = new JSZip();
          const fileName = getBrowserInfo()?.code === 'SF' ? 'video.mp4' : 'video.webm'
          videoZip.file(fileName, capturedVideo);
          const videoZipBlob = await videoZip.generateAsync({ type: 'blob' });
          uploadPromises.push(uploadFile(videoZipBlob, "zip", "zip", "videoUrl"))
        }
        if (capturedImages) {
          uploadPromises.push(uploadFile(capturedImages, "zip", "zip", "imagesDuringUrl"))
        }


        // const imgZip = new JSZip();
        // capturedImages.forEach((image, index) => {
        //   imgZip.file(`image_${index + 1}.png`, image.blob);
        // });
        // const imgZipBlob = await imgZip.generateAsync({ type: 'blob' });

        // const [videoUrl, imagesDuringUrl, imagesBeforeUrl] = await Promise.all([
        //   uploadFile(capturedVideo, "ZIP"),
        //   uploadFile(capturedImages, "ZIP"),
        //   uploadFile(imageSrc, "IMAGE")
        // ]);



        const promiseRes = await progressPromise(uploadPromises, update)

        await dispatch(submitProctoringData({
          proctored_video_url: promiseRes?.filter(item => item?.name === "videoUrl")?.[0]?.fileUrl || "",
          proctored_images_before_quiz_url: promiseRes?.filter(item => item?.name === "imagesBeforeUrl")?.[0]?.fileUrl || "",
          proctored_images_during_quiz_url: promiseRes?.filter(item => item?.name === "imagesDuringUrl")?.[0]?.fileUrl || "",
          tab_switch_count,
          full_screen_exit_count,
          background_pop_up_count,
          attempt_id: attempt_id
        }));
        goToSummary();
      } catch (error) {
        console.error("Error uploading files", error);
      }
    };

    uploadFiles();

  }, [capturedVideo, capturedImages, imageSrc]);

  const goToSummary = () => {
    navigate(`/quiz/summary`, {
      state: { kidId, acm_quiz_id, attempt_id, eventId },
      replace: true
    });
  };

  const progressPromise = (promises, tickCallback) => {
    var len = promises.length;
    var progress = 0;

    const tick = (promise) => {
      promise.then(function () {
        progress++;
        tickCallback(progress, len);
      });
      return promise;
    }

    return Promise.all(promises.map(tick));
  }

  const update = (completed, total) => {
    const progressValue = Math.round(completed / total * 100);
    setProgress(progressValue);
  }

  return (
    <Base>
      <div className="loader-page p-4">
        <div className="mb-5"><img src={loaderServer} className="mw-100" /></div>
        <div className="d-inline-block text-center my-4">
          <InScreenLoader />
        </div>
        <h2 className="mb-2 mt-5 quicksand">Please wait while we are submitting your answers</h2>
        <h2 className="quicksand">Please don’t click back or close the app</h2>
      </div>
    </Base>
  );
};

export default QuizUpload;
