import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Landing from './Pages/Landing';
import Participant from './Pages/Participant';
import PrivateRoute from "./components/PrivateRoute";
import Register from './Pages/Register';
import Dashboard from "./Pages/Dashboard";
import Quiz from './Pages/Quiz';
import QuizSummary from './Pages/QuizSummary';
import CameraCapture from './Pages/CameraCapture';
import CameraPermission from './Pages/CameraPermission';
import MicrophonePermission from './Pages/MicrophonePermission';
import FullScreenPermission from './Pages/FullScreenPermission';
import QuizUpload from './Pages/QuizUpload';
import LeaderBoard from './Pages/LeaderBoard/LeaderBoard';
import "./helper/interceptor"
import ReportCard from './Pages/ReportCard';


function App() {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/participant" element={<PrivateRoute component={Participant} />} />
      <Route path="/register" element={<PrivateRoute component={Register} />} />
      <Route path="/dashboard" element={<PrivateRoute component={Dashboard} />} />
      <Route path="/quiz/camera" element={<PrivateRoute component={CameraPermission} />} />
      <Route path="/quiz/microphone" element={<PrivateRoute component={MicrophonePermission} />} />
      <Route path="/quiz/fullscreen" element={<PrivateRoute component={FullScreenPermission} />} />
      <Route path="/quiz/capture" element={<PrivateRoute component={CameraCapture} />} />
      <Route path="/quiz/start" element={<PrivateRoute component={Quiz} />} />
      <Route path="/quiz/upload" element={<PrivateRoute component={QuizUpload} />} />
      <Route path="/quiz/summary" element={<PrivateRoute component={QuizSummary} />} />
      <Route path="/reportcard" element={<PrivateRoute component={ReportCard} />} />
      <Route path="/leaderboard" element={<LeaderBoard />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

export default App;
