import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import AnswerCard from "./components/AnswerCard";
import Question from "./components/Question";
import { useDispatch, useSelector } from "react-redux";
import { Confirm, Loading, Notify, Report } from "notiflix";
import VideoRecorder from "../../components/VideoRecorder";
import { BASEPATH, MEDIAPATH } from "../../services/config";
import { isString, subtractNonNegative } from "../../helper";
import { useLocation, useNavigate } from "react-router-dom";
import ProgressCircle from "../../components/ProgressCircle";
import { checkQuizQuestion, completeQuiz, fetchQuizQuestion, resetQuiz, setLocalData } from "../../features/quizSlice";
import Base from "../../components/Layout/Base";

const Quiz = () => {

  const containerRef = useRef(null);
  const childRef = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const timerRef = useRef();

  const kidId = location?.state?.kidId;
  const eventId = location?.state?.eventId;
  const imageSrc = location?.state?.imageSrc;

  const { kidList } = useSelector(state => state.shared);
  const { history: quizHistory, proctorData: quizProctorData, localData: quizLocalData, quizData, questionData } = useSelector(state => state.quiz);

  const [timer, setTimer] = useState(10);
  const [hintOption, setHintOption] = useState(false);
  const [answer, setAnswer] = useState([]);
  const [capturedImagesZip, setCapturedImagesZip] = useState(null);
  const [capturedVideoZip, setCapturedVideoZip] = useState(null);

  const [tabSwitchCount, setTabSwitchCount] = useState(0);
  const [fullScreenExitCount, setFullScreenExitCount] = useState(0);
  const [windowBlurCount, setWindowBlurCount] = useState(0);

  const kid = kidList?.filter(item => item.id == kidId)?.[0];

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        setTabSwitchCount((prevCount) => prevCount + 1);
      }
    };

    const handleFullScreenChange = () => {
      if (!document.fullscreenElement) {
        setFullScreenExitCount((prevCount) => prevCount + 1);
      }
    };

    const handleBlur = () => {
      setWindowBlurCount((prevCount) => prevCount + 1);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    document.addEventListener("fullscreenchange", handleFullScreenChange);
    window.addEventListener("blur", handleBlur);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
      window.removeEventListener("blur", handleBlur);
    };
  }, []);


  useEffect(() => {
    document.body.classList.add('ict-form-detail-theme');
    return () => {
      document.body.classList.remove('ict-form-detail-theme');
      if (window.document.fullscreenElement) {
        window.document.exitFullscreen();
      }
    };
  }, []);

  useEffect(() => {
    if (quizLocalData?.summary_redirect) {
      if (quizProctorData?.is_proctoring) {
        if (childRef.current) {
          Loading.standard();
          childRef.current.stopRecording();
          goToUploadPage();
        }
      } else {
        navigate(`/quiz/summary`, {
          state: {
            kidId,
            acm_quiz_id: quizData?.acm_quiz_id,
            attempt_id: quizData?.attempt_id,
            eventId
          },
          replace: true
        });
      }
    }
  }, [quizLocalData?.summary_redirect, capturedVideoZip, capturedImagesZip])


  const goToUploadPage = () => {
    Loading.remove();
    const data = {
      kidId,
      acm_quiz_id: quizData?.acm_quiz_id,
      attempt_id: quizData?.attempt_id,
      eventId,
      tab_switch_count: tabSwitchCount,
      full_screen_exit_count: fullScreenExitCount,
      background_pop_up_count: subtractNonNegative(windowBlurCount - tabSwitchCount),
    }
    if (quizProctorData?.videos_count && quizProctorData?.images_count) {
      if (capturedVideoZip && capturedImagesZip) {
        navigate(`/quiz/upload`, {
          state: {
            ...data,
            capturedVideoZip,
            capturedImagesZip,
            imageSrc
          },
          replace: true
        });
      }
    } else if (quizProctorData?.videos_count && capturedVideoZip) {
      navigate(`/quiz/upload`, {
        state: {
          ...data,
          capturedVideoZip,
          imageSrc
        },
        replace: true
      });

    } else if (quizProctorData?.images_count && capturedImagesZip) {
      navigate(`/quiz/upload`, {
        state: {
          ...data,
          capturedImagesZip,
          imageSrc
        },
        replace: true
      });
    }
  }

  useEffect(() => {
    if (!quizHistory?.length) {
      navigate(-1)
    }
  }, [quizHistory]);

  useEffect(() => {
    timerRef.current = setInterval(() => {
      if (timer > 0) setTimer(prev => prev - 1);
    }, 1000);

    return () => { clearInterval(timerRef.current) }
  }, []);

  useEffect(() => {
    if (timer < 1) {
      clearInterval(timerRef.current);
    }
    if (timer < 1 && !quizLocalData?.attempt_complete_status) {
      setTimer(0);
      finishQuiz({ attempt_id: quizData?.attempt_id, time_taken: quizLocalData?.quiz_time_remaining, time_over: true });
    }
  }, [timer])

  useEffect(() => {
    if (quizLocalData?.quiz_time_remaining) {
      setTimer(parseInt(quizLocalData?.quiz_time_remaining));
    }
  }, [quizLocalData])

  useEffect(() => {
    if (quizData?.attempt_id)
      dispatch(fetchQuizQuestion({ attempt_id: quizData?.attempt_id, submit_type: "New", quiz_time_remaining: quizLocalData?.quiz_time_remaining }))

    return () => dispatch(resetQuiz());
  }, [quizData])

  const handleAutoCaptured = (images) => {
    setCapturedImagesZip(images);
  };

  const handleVideoCaptured = (blob) => {
    setCapturedVideoZip(blob);
  };

  const getPercentage = (value, total) => {
    return Math.floor((value / total) * 100);
  }

  const covertTime = (timer) => {
    if (timer > 0) {
      let min = Math.floor(timer / 60);
      let sec = Math.floor(timer % 60);
      if (min < 10) {
        min = `0${min}`
      }
      if (sec < 10) {
        sec = `0${sec}`
      }
      return `${min}:${sec} ${min < 1 ? 'sec' : 'min'}`;
    } else {
      return `00:00 sec`
    }
  }

  const handleAnswer = (val, single) => {
    const value = parseInt(val);
    if (single) {
      setAnswer([value]);
    } else {
      if (answer.indexOf(value) >= 0) {
        const newSet = [...answer]
        newSet.splice(answer.indexOf(value), 1);
        setAnswer(newSet);
      } else {
        setAnswer([...answer, value]);
      }
    }
  }

  const handleCheck = () => {
    if (!answer.length)
      return false

    const time_taken = Math.floor((new Date().getTime() - quizLocalData.timestamp) / 1000);
    const data = {
      attempt_id: quizData?.attempt_id,
      question_id: questionData?.question_id,
      selected_options: answer,
      time_taken,
      quiz_time_remaining: quizLocalData?.quiz_time_remaining
    }
    dispatch(checkQuizQuestion(data));
  }

  const handleContinue = () => {
    const time_taken = Math.floor((new Date().getTime() - quizLocalData.timestamp) / 1000);
    const data = {
      attempt_id: quizData?.attempt_id,
      submit_type: "New",
      time_taken,
      quiz_time_remaining: quizLocalData?.quiz_time_remaining
    }
    setAnswer([]);
    dispatch(fetchQuizQuestion(data))
  }

  const handleSkip = () => {
    const time_taken = Math.floor((new Date().getTime() - quizLocalData.timestamp) / 1000);
    const data = {
      attempt_id: quizData?.attempt_id,
      question_id: questionData?.question_id,
      submit_type: "Skip",
      time_taken,
      quiz_time_remaining: quizLocalData?.quiz_time_remaining
    }
    setAnswer([]);
    dispatch(fetchQuizQuestion(data))
  }

  const handleComplete = () => {
    if (quizLocalData?.finish_popup_one_button) {
      Report.success(
        "Kindly click Finish to complete the Quiz",
        '',
        'Finish',
        function cb() {
          finishQuiz({ attempt_id: quizData?.attempt_id });
        },
        {
          titleColor: "#9E54E8",
          okButtonBackground: "#9E54E8"
        }
      );
    } else {
      Confirm.show(
        'KidEx - Quiz',
        `Kindly click Finish to complete the Quiz`,
        'Finish',
        'Cancel',
        function okCb() {
          finishQuiz({ attempt_id: quizData?.attempt_id })
        },
        function cancelCb() { },
        {
          className: 'notiflix-confirm',
          width: '320px',
          borderRadius: '8px',
          titleColor: "#9E54E8",
          okButtonBackground: "#9E54E8"
        },
      );
    }
  }

  const finishQuiz = async (postData) => {

    const { time_over } = postData;
    const actionResult = await dispatch(completeQuiz(postData)).unwrap();
    if (actionResult?.status) {
      const localData = {
        quiz_time_remaining: 1,
        summary_button: actionResult?.data?.summary_button,
        correct_options: actionResult?.data?.correct_options,
        continue_button: actionResult?.data?.continue_button,
        attempt_complete_status: actionResult?.data?.attempt_complete_status,
        timestamp: new Date().getTime()
      }
      dispatch(setLocalData(localData));
      if (time_over) {
        const message = time_over ? "Oops! Time over for this quiz." : "Quiz completed.";
        Report.success(
          message,
          '',
          'Okay',
          function cb() {
            dispatch(setLocalData({ summary_redirect: true }))
          },
          {
            titleFontSize: "18px",
            success: {
              svgColor: '#9E54E8',
              titleColor: '#1e1e1e',
              messageColor: '#242424',
              buttonBackground: '#9E54E8',
              buttonColor: '#fff',
              backOverlayColor: 'rgb(158, 84, 232,0.2)',
            },
          }
        );
      } else {
        dispatch(setLocalData({ summary_redirect: true }))
      }

    } else if (actionResult?.message) {
      Notify.warning(actionResult?.message);
    } else {
      Notify.warning("something went wrong");
    }
  }

  return (
    <div className="maindiv ict-quiz" ref={containerRef} style={{ background: "#FFF", width: '100vw', height: '100vh' }}>
      <div>
        <div className="quiz_banner ict-quiz-banner">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <div className="quiz-banner-txt">
                <h3 className="text-white"><span className="text-white">Name of the Quiz</span> {quizData?.quiz_name}</h3>
              </div>
              <div className="quiz-banner-txt">
                <h3 className="text-white"> <span className="text-white">Total Time</span> {covertTime(quizData?.time_limit)}</h3>
              </div>
              <div className="d-none d-md-block">
                <div className="recording-timer">
                  <ProgressCircle size={55} strokeWidth={5} percentage={(Math.round(timer * 100) / quizData?.time_limit)} text={covertTime(timer)?.split(" ").shift()} subtext={covertTime(timer)?.split(" ").pop()} />
                </div>
              </div>
              <div>
                <div className="user_img ">
                  {quizProctorData?.is_proctoring &&
                    <div className="d-flex gap-1 fs-9px">
                      <div className="reddot my-auto"></div>
                      <small className="ff-inter">Recording</small>
                    </div>
                  }
                  {quizProctorData?.is_proctoring ?
                    <VideoRecorder
                      ref={childRef}
                      proctoring_type={quizProctorData?.proctoring_type}
                      video_proctoring_type={quizProctorData?.video_proctoring_type}
                      images_count={quizProctorData?.images_count}
                      video_duration={quizProctorData?.video_duration}
                      videos_count={quizProctorData?.videos_count}
                      quiz_duration={quizProctorData?.quiz_time}
                      onAutoCaptured={handleAutoCaptured}
                      onVideoCaptured={handleVideoCaptured}
                    />
                    : <img src={isString(kid?.profileUrl) ? kid?.profileUrl : `${MEDIAPATH}/skin/images/profile/profile.jpeg`} width={90} />
                  }
                  <div className="text-center fs-9px">
                    <small className="ff-inter">{kid?.name}</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="container">
            <div className="row py-3">
              <div className="col-md-4 mx-auto text-center ">
                <div className="d-flex d-md-block align-items-center">
                  <div className="flex-1">
                    <div className="quiz-progress-bar">
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: `${getPercentage(questionData?.current_question_number, questionData?.total_quiz_question)}%` }}
                          aria-valuenow={`${getPercentage(questionData?.current_question_number, questionData?.total_quiz_question)}`}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                    <div className="pt-2">
                      <h6 className="ff-montserrat mob-font  text-purple fw-bolder mb-0 text-white">Question {questionData?.current_question_number} of {questionData?.total_quiz_question}</h6>
                    </div>
                  </div>
                  <div className="d-block d-md-none">
                    <div className="recording-timer">
                      <div className="recording-timer">
                        <ProgressCircle size={55} strokeWidth={5} percentage={(Math.round(timer * 100) / quizData?.time_limit)} text={covertTime(timer)?.split(" ").shift()} subtext={covertTime(timer)?.split(" ").pop()} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Question data={questionData} setAnswer={setAnswer} />
          </div>
        </div>
      </div>
      <div className="quiz-option">
        <div className="container">
          <div className="d-md-table">
            {questionData?.options?.map((item, index) => {
              return <AnswerCard questionData={item} quizLocalData={quizLocalData} handleAnswer={handleAnswer} answer={answer} isClickable={!(quizLocalData?.continue_button || quizLocalData?.show_complete_button)} />
            })}
          </div>
        </div>
      </div>
      <div className={`fixed-btn-ftr ict-quiz-footer ${quizLocalData?.correct_options === true && "selected-correct"} ${quizLocalData?.correct_options === false && "selected-incorrect"}`}>
        <div className="container">
          <div className={`text-danger d-flex justify-content-${(quizLocalData?.skip_button || quizLocalData?.correct_options !== null) ? 'between' : 'end'} py-3`}>
            {(quizLocalData?.correct_options != null && (quizLocalData?.continue_button || quizLocalData?.show_complete_button)) &&
              <h4 className="text-success fw-bold d-flex gap-2 ff1 my-auto">
                {quizLocalData?.correct_options ?
                  <img src={`${BASEPATH}/svg/thumb_up.svg`} className="my-auto" />
                  : <img src={`${BASEPATH}/svg/hand_down_sm.svg`} className="my-auto" />
                }
                <span className="my-auto">{quizLocalData?.correct_options ? "Correct answer!" : "That’s incorrect"}</span>
              </h4>}
            {quizLocalData?.skip_button && !quizLocalData?.summary_button && !quizLocalData?.continue_button && <button onClick={handleSkip} className="quiz-btn footer-site-btn quiz-border-btn me-2">Skip</button>}
            {quizLocalData?.check_button && !quizLocalData?.summary_button && !quizLocalData?.continue_button && <button onClick={handleCheck} className={`site-btn footer-site-btn quiz-fix-btn ${answer?.length ? "" : "disabled_btn"}`} disabled={!answer.length}>Check</button>}
            {quizLocalData?.continue_button && questionData?.is_single_choice && <button onClick={handleContinue} className="quiz-btn footer-site-btn">Continue</button>}
            {quizLocalData?.show_complete_button && !quizLocalData?.attempt_complete_status && <button button onClick={handleComplete} className="quiz-btn footer-site-btn">Complete</button>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Quiz;
