import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import Webcam from 'react-webcam';
import "./style.css";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { completeQuiz, setLocalData, setQuizData, startQuiz } from '../../features/quizSlice';
import { BASEPATH } from '../../services/config';
import PopUp from '../../components/PopUp';
import { Notify } from 'notiflix';
import Base from '../../components/Layout/Base';

const CameraCapture = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const webcamRef = useRef(null);

  const eventId = location.state?.eventId;
  const kidId = location?.state?.kidId;

  const { kidsList } = useSelector(state => state.shared);
  const { history: quizHistory, quizData, questionData } = useSelector(state => state.quiz);

  const [showModal, setShowModal] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [reload, setReload] = useState(0);

  const kid = kidsList?.filter(item => item.id == kidId)?.[0];

  useEffect(() => {
    document.body.classList.add('ict-form-detail-theme');
    return () => {
      document.body.classList.remove('ict-form-detail-theme');
    };
  }, [])

  useEffect(() => {
    if (quizData?.acm_quiz_id)
      navigate(`/quiz/start`, {
        state: { kidId, eventId, imageSrc },
        replace: true
      });
  }, [quizData, reload])

  const startTest = async () => {
    setReload(Math.random());
    const resultAction = await dispatch(startQuiz({ kidId, acm_quiz_id: quizHistory?.[0]?.acm_quiz_id })).unwrap();
    if (resultAction?.status) {
      dispatch(setQuizData(resultAction?.data))
    } else if (resultAction?.message == "Oops! Time over for the quiz" && quizHistory?.[0]?.incomplete_attempt_id) {
      dispatch(completeQuiz({ attempt_id: quizHistory?.[0]?.incomplete_attempt_id }));
    } else if (resultAction?.message) {
      dispatch(setQuizData({ message: resultAction?.message }))
      Notify.warning(resultAction?.message);
    } else {
      Notify.warning("something went wrong");
    }
    const data = {
      summary_button: resultAction?.data?.summary_button,
      attempt_complete_status: resultAction?.data?.attempt_complete_status,
      show_complete_button: false,
      show_retake_button: false,
    }
    dispatch(setLocalData(data));
  }

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImageSrc(imageSrc);
  }, [webcamRef]);

  const retry = () => {
    setImageSrc(null);
  };

  return (
    <Fragment>
      <div className="quiz-name-page pt-5">
        <div className='container'>
          <div className='cc-content'>
            <h2 className='cc-name Quicksand mb-3 mb-md-5 text-white'><span className='text-white'>Name:</span> {kid?.name}</h2>
            {!imageSrc && <div className='cc-img'>
              <Webcam
                ref={webcamRef}
                audio={false}
                mirrored={true}
                style={{ width: '400px', height: '400px' }}
                screenshotFormat="image/jpeg"
              />
              <div className='quiz-control-btn  white-theme-btn  flex-column justify-content-center align-items-center'>
                <button onClick={capture} className='quiz-btn px-5 w-280 mw-230'>Capture</button>
              </div>
            </div>}
            <div className='clear'></div>
          </div>
          {imageSrc &&
            <>
              <div className='d-flex justify-content-center align-items-center'><img src={imageSrc} alt="Captured" style={{ width: '400px', height: '300px' }} /></div>
              <div className='quiz-control-btn  white-theme-btn  pt-5'>
                <button onClick={retry} className='quiz-btn quiz-border-btn px-5 w-280 mw-230 me-2'>Retry</button>
                <button onClick={() => setShowModal(true)} className='quiz-btn px-5 w-280 mw-230' data-bs-toggle='modal' data-bs-target='#quiz-start'>Submit</button>
              </div>
            </>
          }
        </div>
      </div>
      <PopUp show={showModal} handleClose={() => setShowModal(false)}>
        <div className='modal-camera-perm'>
          <img src={`${BASEPATH}/images/quiz/like-dark.svg`} />
          <h2>{`You have ${Math.floor(quizHistory?.[0]?.quiz_time / 60)} mins to complete the quiz. Good luck!`}</h2>
          <button onClick={startTest} data-bs-dismiss="modal" className='quiz-btn mb-3 px-5 w-280 mw-230 value-fill border-0'>Start the Quiz</button>
        </div>
      </PopUp>
    </Fragment>
  )
}

export default CameraCapture