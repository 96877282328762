import React from 'react'
import PropTypes from 'prop-types';
import Navigation from './Navigation'

const Base = (props) => {
  return (
    <div id="car-quiz" className="">
      <Navigation />
      {props.children}
    </div>
  )
}

Base.propTypes = {
  children: PropTypes.object
}
export default Base