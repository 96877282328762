import React, { Fragment, useEffect, useState } from "react";
import "../Quiz/style.css";
import { BASEPATH, MEDIAPATH } from "../../services/config";
import { useDispatch, useSelector } from "react-redux";
import { isString } from "../../helper";
import { Link, useLocation } from "react-router-dom";
import Base from "../../components/Layout/Base";
import { fetchCertificate } from "../../features/quizSlice";

const QuizSummary = () => {

  const dispatch = useDispatch();
  const location = useLocation();

  const kidId = location?.state?.kidId;
  const acm_quiz_id = location?.state?.acm_quiz_id;
  const attempt_id = location?.state?.attempt_id;
  const eventId = location?.state?.eventId;

  const { kidsList } = useSelector(state => state.shared);

  const [certifcate, setCertificate] = useState("")

  const kid = kidsList?.filter(item => item.id == kidId)?.[0];

  useEffect(() => {
    document.body.classList.add('ict-form-detail-theme');
    return () => {
      document.body.classList.remove('ict-form-detail-theme');
    };
  }, []);

  useEffect(() => {
    downloadQuizCertificate({ kidId, competitionId: eventId, certificateType: 3, quizAttemptId: attempt_id });
  }, [])

  const downloadQuizCertificate = async (params) => {
    let { counter } = params;
    delete params["counter"];
    counter = !counter ? 1 : counter;
    const resultAction = await dispatch(fetchCertificate(params)).unwrap();
    if (resultAction?.status) {
      setCertificate(resultAction?.data?.certificateImageUrl);
    } else {
      if (!counter || counter < 5) {
        counter++;
        setTimeout(() => {
          downloadQuizCertificate({ ...params, counter });
        }, 2000);
      }
    }
  }

  const viewCertificate = () => {
    window.open(certifcate, "_blank");
  }

  return (
    <Base>
      <div className="btn-vector">
        <div className="container basic-profile-details-show py-5">
          <div className="text-center">
            <img src={`${BASEPATH}/images/ict-quiz/all-rounder-logo.png`} className="allrounder-logo" />
            <div className="congra-txt-gr">Congratulations</div>
          </div>
          <div class="row py-5">
            <div class="col-5 col-md-6 d-flex justify-content-end">
              <div className="ict-img-upload">
                <img src={isString(kid?.profileUrl) ? kid?.profileUrl : `${MEDIAPATH}/skin/images/profile/profile.jpeg`} className="ict-user-img" />
              </div>
            </div>
            <div class="col-7 col-md-6 d-flex align-items-center">
              <div className="basic-profile-details-content">
                <h2 className="basic-profile-details-title">{kid?.name}</h2>
              </div>
            </div>
            <div className="col-12">
              <p className="basic-profile-details-para">We are thrilled to acknowledge your successful completion of the first round of the Classmate All Rounder competition! Your commitment and participation are truly commendable.</p>
            </div>
          </div>
          <div className="guidelines-sec">
            <h2 className="guidelines-title quicksand">Guidelines</h2>
            <ul className="guidelines-list quicksand">
              <li><span>1</span><b>You can take the quiz in full screen mode only</b></li>
              <li><span>2</span><b>You may be required to submit a photo of yourself for verification purposes</b></li>
              <li><span>3</span><b>You will not be able to change/toggle screens without completing quiz</b></li>
              <li><span>4</span><b>You have to give camera & microphone permissions to enable video proctoring, as applicable</b></li>
              <li><span>5</span><b>Your class teacher will also have access to your report card.</b></li>
            </ul>
          </div>
        </div>
        <div class="static-footer">
          <div class="container">
            <div class="text-danger d-flex py-4 justify-content-between congrats-btn-ftr flex-wrap">
              <Link to="/participant" class="quiz-btn bg-transparent white-border-btn me-2">Home</Link>
              <button class="quiz-btn bg-transparent white-border-btn me-2">Leaderboard</button>
              <Link to="/reportcard" state={{ kidId, acm_quiz_id, attempt_id }} class="quiz-btn bg-transparent white-border-btn  me-2">Report Card</Link>
              {certifcate != "" && <button onClick={viewCertificate} class="quiz-btn bg-transparent white-border-btn pc-btn">Participation Certificate</button>}
            </div>
          </div>
        </div>
      </div>
    </Base>
  )
}

export default QuizSummary