// BarChart.js
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
// Register the necessary components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
ChartJS.register(ChartDataLabels);

const BarChart = ({ data }) => {

  const icons = [
    'https://cdn-icons-png.flaticon.com/512/733/733547.png',
    'https://cdn-icons-png.flaticon.com/512/733/733558.png',
    'https://cdn-icons-png.flaticon.com/512/733/733579.png',
    'https://cdn-icons-png.flaticon.com/512/2111/2111646.png',
    'https://cdn-icons-png.flaticon.com/512/733/733553.png',
    'https://cdn-icons-png.flaticon.com/512/2111/2111370.png',
  ];


  // Custom plugin for rendering icons in the X-axis
  const iconPlugin = {
    id: 'iconPlugin',
    beforeDraw: (chart) => {
      const ctx = chart.ctx;
      const xScale = chart.scales.x;
      // Loop through each tick
      xScale.ticks.forEach((tick, index) => {
        const img = new Image();
        img.src = icons[index];
        img.onload = () => {
          // Draw the icon at the appropriate position
          ctx.drawImage(img, tick.x - 10, xScale.bottom + 10, 20, 20); // Adjust position and size
        };
      });
    },
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'top',
      },
      tooltip: {
        enabled: true,
      },
      datalabels: {
        display: true,
        color: '#4E3070',
        font: {
          size: 14,
          weight: 'bold',
        },
        anchor: 'center',
        align: 'center',
        rotation: -90,
        formatter: (value) => value,
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#DDBFFF',
          font: {
            size: 14,
          },
        },
        // ticks: {
        //   callback: function (value, index) {
        //     const img = new Image();
        //     img.src = icons[index];
        //     return img;
        //   },
        //   font: {
        //     size: 14,
        //   },
        // },
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: 'Development Pillars',
          font: {
            size: 20,
            weight: '400',
          },
          color: '#DDBFFF'
        },
        border: {
          color: '#CDB2EB',
        },
        color: '#CDB2EB',
      },
      y: {
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: 'Score',
          font: {
            size: 20,
            weight: '400',
          },
          color: '#DDBFFF',
        },
        ticks: {
          color: '#DDBFFF',
          font: {
            size: 14,
          },
          beginAtZero: true,
        },
        border: {
          color: '#CDB2EB',
        },
      },
    },
  };

  return <Bar data={data} options={options} plugins={[iconPlugin]} />;
};

export default BarChart;
