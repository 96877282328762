import React, {useEffect} from "react";
import './style.css'
import Dropdown from 'react-bootstrap/Dropdown';

const LeaderBoard = () => {
    useEffect(() => {
        document.body.classList.add('ict-form-detail-theme');
        return () => {
          document.body.classList.remove('ict-form-detail-theme');
        };
      }, []);
    return (
        <>
        <div className="ict-quiz-class">
          <div className="container ict-quiz-banner-container">
            <div className="quiz_banner ict-quiz-banner">
              <div className="container">
                <div className="banner-filter-sec">
                    <h3 className="filter-txt">Filter by:</h3>
                    <div className="d-flex flex-wrap">
                    <Dropdown className="selectbox-dropdown">
                      <Dropdown.Toggle id="dropdown-basic">
                      Competitions
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">SS Narsee School, Delhi</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Delhi Public School, Guwahati</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Kendriya Vidyalaya, Mathura</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className="selectbox-dropdown">
                      <Dropdown.Toggle id="dropdown-basic">
                      All Rounder Quiz
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">SS Narsee School, Delhi</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Delhi Public School, Guwahati</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Kendriya Vidyalaya, Mathura</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className="selectbox-dropdown">
                      <Dropdown.Toggle id="dropdown-basic">
                      School
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">SS Narsee School, Delhi</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Delhi Public School, Guwahati</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Kendriya Vidyalaya, Mathura</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <button type="button" class="site-btn white-btn ms-sm-5">Apply</button>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row mb-5">
              <div className="col-md-12"><div className="congra-txt-gr px-5">Class 2</div></div>
              <div className="col-6 col-md-4">
                <div className="quiz-winner-bx">
                  <div className="winner-img">
                    <img src="../../images/ict-quiz/crown-1.svg" />
                  </div>
                  <h3 className="winner-name">Pratham Singh</h3>
                  <p className="winner-school nunito">Delhi Public School</p>
                  <p className="winner-address nunito">Guwahati, Assam</p>
                  <div className="start-divider"><img src="../../images/ict-quiz/star.svg" /></div>
                  <div className="winner-score">
                    <div className="score-txt nunito">Total</div>
                    <div className="score">90</div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-4">
                <div className="quiz-winner-bx">
                  <div className="winner-img">
                    <img src="../../images/ict-quiz/crown-2.svg" />
                  </div>
                  <h3 className="winner-name">Pratham Singh</h3>
                  <p className="winner-school nunito">Delhi Public School</p>
                  <p className="winner-address nunito">Guwahati, Assam</p>
                  <div className="start-divider"><img src="../../images/ict-quiz/star.svg" /></div>
                  <div className="winner-score">
                    <div className="score-txt nunito">Total</div>
                    <div className="score">90</div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-4">
                <div className="quiz-winner-bx">
                  <div className="winner-img">
                    <img src="../../images/ict-quiz/crown-3.svg" />
                  </div>
                  <h3 className="winner-name">Pratham Singh</h3>
                  <p className="winner-school nunito">Delhi Public School</p>
                  <p className="winner-address nunito">Guwahati, Assam</p>
                  <div className="start-divider"><img src="../../images/ict-quiz/star.svg" /></div>
                  <div className="winner-score">
                    <div className="score-txt nunito">Total</div>
                    <div className="score">90</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-md-12"><div className="congra-txt-gr px-5">Class 1</div></div>
              <div className="col-6 col-md-4">
                <div className="quiz-winner-bx">
                  <div className="winner-img">
                    <img src="../../images/ict-quiz/crown-1.svg" />
                  </div>
                  <h3 className="winner-name">Pratham Singh</h3>
                  <p className="winner-school nunito">Delhi Public School</p>
                  <p className="winner-address nunito">Guwahati, Assam</p>
                  <div className="start-divider"><img src="../../images/ict-quiz/star.svg" /></div>
                  <div className="winner-score">
                    <div className="score-txt nunito">Total</div>
                    <div className="score">90</div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-4">
                <div className="quiz-winner-bx">
                  <div className="winner-img">
                    <img src="../../images/ict-quiz/crown-2.svg" />
                  </div>
                  <h3 className="winner-name">Pratham Singh</h3>
                  <p className="winner-school nunito">Delhi Public School</p>
                  <p className="winner-address nunito">Guwahati, Assam</p>
                  <div className="start-divider"><img src="../../images/ict-quiz/star.svg" /></div>
                  <div className="winner-score">
                    <div className="score-txt nunito">Total</div>
                    <div className="score">90</div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-4">
                <div className="quiz-winner-bx">
                  <div className="winner-img">
                    <img src="../../images/ict-quiz/crown-3.svg" />
                  </div>
                  <h3 className="winner-name">Pratham Singh</h3>
                  <p className="winner-school nunito">Delhi Public School</p>
                  <p className="winner-address nunito">Guwahati, Assam</p>
                  <div className="start-divider"><img src="../../images/ict-quiz/star.svg" /></div>
                  <div className="winner-score">
                    <div className="score-txt nunito">Total</div>
                    <div className="score">90</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-md-12"><div className="congra-txt-gr px-5">Class 3</div></div>
              <div className="col-6 col-md-4">
                <div className="quiz-winner-bx">
                  <div className="winner-img">
                    <img src="../../images/ict-quiz/crown-1.svg" />
                  </div>
                  <h3 className="winner-name">Pratham Singh</h3>
                  <p className="winner-school nunito">Delhi Public School</p>
                  <p className="winner-address nunito">Guwahati, Assam</p>
                  <div className="start-divider"><img src="../../images/ict-quiz/star.svg" /></div>
                  <div className="winner-score">
                    <div className="score-txt nunito">Total</div>
                    <div className="score">90</div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-4">
                <div className="quiz-winner-bx">
                  <div className="winner-img">
                    <img src="../../images/ict-quiz/crown-2.svg" />
                  </div>
                  <h3 className="winner-name">Pratham Singh</h3>
                  <p className="winner-school nunito">Delhi Public School</p>
                  <p className="winner-address nunito">Guwahati, Assam</p>
                  <div className="start-divider"><img src="../../images/ict-quiz/star.svg" /></div>
                  <div className="winner-score">
                    <div className="score-txt nunito">Total</div>
                    <div className="score">90</div>
                  </div>
                </div>
              </div>
              <div className="col-6 col-md-4">
                <div className="quiz-winner-bx">
                  <div className="winner-img">
                    <img src="../../images/ict-quiz/crown-3.svg" />
                  </div>
                  <h3 className="winner-name">Pratham Singh</h3>
                  <p className="winner-school nunito">Delhi Public School</p>
                  <p className="winner-address nunito">Guwahati, Assam</p>
                  <div className="start-divider"><img src="../../images/ict-quiz/star.svg" /></div>
                  <div className="winner-score">
                    <div className="score-txt nunito">Total</div>
                    <div className="score">90</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </>
    )
}

export default LeaderBoard