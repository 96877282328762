import React, { Fragment, useEffect, useState } from "react";
import "../Quiz/style.css";
import { MEDIAPATH } from "../../services/config";
import Base from "../../components/Layout/Base";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchQuizSummary } from "../../features/quizSlice";
import { formatSeconds, isString } from "../../helper";
import RadarChart from "../../components/Charts/RadarChart";
import { radarConfigs } from "../../utils";
import BarChart from "../../components/Charts/BarChart";

const ReportCard = () => {

  const dispatch = useDispatch();
  const location = useLocation();

  const kidId = location?.state?.kidId;
  const acm_quiz_id = location?.state?.acm_quiz_id;
  const attempt_id = location?.state?.attempt_id;

  const { kidsList } = useSelector(state => state.shared);
  const { reportCard } = useSelector(state => state.quiz);

  const kid = kidsList?.filter(item => item.id == kidId)?.[0];

  useEffect(() => {
    document.body.classList.add('ict-form-detail-theme');
    return () => {
      document.body.classList.remove('ict-form-detail-theme');
    };
  }, []);

  useEffect(() => {
    const data = {
      kidId,
      acm_quiz_id,
      attempt_id
    }
    dispatch(fetchQuizSummary(data));
  }, [])

  const radarData = {
    labels: reportCard?.category_scores?.map(item => (`${item?.category_name}`)),
    datasets: [
      {
        label: "",
        data: reportCard?.category_scores?.map(item => Math.ceil(item?.score_percentage)),
        backgroundColor: "rgba(255, 210, 240,0.5)",
        borderColor: "#FFD2F0",
        fill: true,
      },
    ],
  }

  const barData = {
    labels: reportCard?.category_scores?.map(item => (`${item?.category_name}`)),
    datasets: [
      {
        label: '',
        data: reportCard?.category_scores?.map(item => Math.ceil(item?.score_percentage)),
        backgroundColor: '#CDA1FF',
        borderColor: '#CDA1FF',
        borderWidth: 1,
        borderRadius: 50,
        barThickness: 40,
      },
    ],
  };

  return (
    <Base>
      <div className="pb-5">
        <div className="container basic-profile-details-show report-card mt-5 pb-3">
          <div className="text-center d-block d-md-none pt-4 pt-md-0">
            <img src='../../images/ict-quiz/all-rounder-logo.png' className="profile-allronder-logo" />
          </div>
          <div class="row py-5 pt-3 pt-md-5">
            <div class="col-5 col-md-6 d-flex justify-content-end">
              <div className="ict-img-upload d-flex">
                <img src='../../images/ict-quiz/all-rounder-logo.png' className="profile-allronder-logo d-none d-md-block" />
                <img src={isString(kid?.profileUrl) ? kid?.profileUrl : `${MEDIAPATH}/skin/images/profile/profile.jpeg`} className="ict-user-img" />
              </div>
            </div>
            <div class="col-7 col-md-6 d-flex align-items-center">
              <div className="basic-profile-details-content ">
                <h2 className="basic-profile-details-title mb-1">{kid?.name}</h2>
                <p className="mb-2">{kid?.school}</p>
                <p className="mb-2">{kid?.city}</p>
                <div className="d-flex">
                  <p className="me-md-5 mb-2">Class : {kid?.kidClass}</p>
                  <p className="mb-2">Roll No : </p>
                </div>
              </div>
            </div>
          </div>
          <div className="report-card-result-rating">
            <div class="score-card mb-5">
              <div class={`score-rating ${reportCard?.attempt_score_percentage > 80 && "three-star"}`}>
                {reportCard?.attempt_score_percentage > 0 && <img src="https://staging.kid-ex.com/images/quiz/star.png" width="95" />}
                {reportCard?.attempt_score_percentage > 40 && <img src="https://staging.kid-ex.com/images/quiz/star.png" width="95" className="mx-3" />}
                {reportCard?.attempt_score_percentage > 80 && <img src="https://staging.kid-ex.com/images/quiz/star.png" width="95" />}
              </div>
              <h2><span>{Math.round(reportCard?.attempt_score)}</span>Holistic Development Score</h2>
            </div>
            <div className="quiz-report-timer quicksand">
              <img src="../../images/ict-quiz/timer.png" className="me-2 me-md-3" />
              Total Time taken:
              <span> {formatSeconds(reportCard?.time_taken)}</span>
            </div>
          </div>
          <div className="figure-img">
            <RadarChart chartData={radarData} options={radarConfigs} />
            <img className="aesthetic" src="../../images/ict-quiz/aesthetic.png" alt="brain" />
            <img className="cognitive-development" src="../../images/ict-quiz/cognitive-development.png" alt="bulb" />
            <img className="language-literacy" src="../../images/ict-quiz/language.png" alt="multiUsers" />
            <img className="physical-development" src="../../images/ict-quiz/physical-development.png" alt="sports" />
            <img className="positive-learning" src="../../images/ict-quiz/positive-learning.png" alt="socio" />
            <img className="socio-emotional" src="../../images/ict-quiz/socio-emotional.png" alt="positive" />
          </div>
          <div className="figure-img">
            <BarChart data={barData} />
          </div>
        </div>
      </div>
    </Base>
  )
}

export default ReportCard