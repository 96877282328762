import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logoImage from "../../assets/images/ict-quiz/logo.png";
import LeftArrow from "../../assets/images/ict-quiz/LeftArrow.png"
import { logoutUser } from '../../features/authSlice';
import { BASEPATH } from '../../services/config';
import '../../Pages/Participant/style.css';

const Navigation = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuthenticated } = useSelector(state => state.auth);

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate(BASEPATH);
  }

  return (
    <header className="text-center header-section">
      <div className="container">
        <div className='d-flex justify-content-between align-items-center'>
          <img src={logoImage} alt="Logo" className="logo" />
          {isAuthenticated &&
            <Button onClick={handleLogout} variant="link" className="text-white text-decoration-none p-0">
              <img src={LeftArrow} alt='' className='pe-2' />
              Log Out
            </Button>
          }
        </div>
      </div>
    </header>
  );
};

export default Navigation;
