import React from 'react';
import { useSelector } from 'react-redux';
// import Landing from '../Pages/Landing';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ component: Component }) => {

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return isAuthenticated ? <Component /> : <Navigate to="/" replace />;
  // return isAuthenticated ? <Component /> : <Landing />;
};

export default PrivateRoute;
