import React, { useEffect, useState } from 'react'
import { BASEPATH, MEDIAPATH } from '../../services/config';
import { useDispatch, useSelector } from 'react-redux';
import { getAgeByDOB, isString } from '../../helper';
import { completeQuiz, fetchHistory, setLocalData, resetQuiz, setQuizData, startQuiz, resetQuizData, fetchCertificate } from '../../features/quizSlice';
import { Confirm, Notify } from 'notiflix';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { fetchActivities, fetchCateoryList, fetchKidsList } from '../../features/sharedSlice';
import Base from '../../components/Layout/Base';
import { CloudFileUpload, createPresignedURL } from '../../features/cloudSlice';
import { updateKid } from '../../features/userSlice';

const Dashboard = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const kidId = location.state?.kidId;
  const initialState = { alt: "loading...", src: null };

  const { user } = useSelector(state => state.auth);
  const { kidsList, eventDetails } = useSelector(state => state.shared);
  const { history: quizHistory, proctorData: quizProctorData, quizData, questionData, loading } = useSelector(state => state.quiz);

  const [activityId, setActivityId] = useState();
  const [{ alt, src }, setPreview] = useState(initialState);
  const [certifcate, setCertificate] = useState("")

  const kid = kidsList?.filter(item => item.id == kidId)?.[0];

  useEffect(() => {
    dispatch(resetQuizData())
    document.body.classList.add('ict-form-detail-theme');
    return () => {
      document.body.classList.remove('ict-form-detail-theme');
    };
  }, []);

  useEffect(() => {
    getCategoryActivity();
  }, [])

  useEffect(() => {
    if (quizHistory?.[0]?.quiz_attempts?.length > 0) {
      downloadQuizCertificate({ kidId, competitionId: eventDetails?.event_id, certificateType: 3, quizAttemptId: quizHistory?.[0]?.quiz_attempts?.[0]?.attempt_id });
    }
  }, [quizHistory])

  const downloadQuizCertificate = async (params) => {
    let { counter } = params;
    delete params["counter"];
    counter = !counter ? 1 : counter;
    const resultAction = await dispatch(fetchCertificate(params)).unwrap();
    if (resultAction?.status) {
      setCertificate(resultAction?.data?.certificateImageUrl);
    } else {
      if (!counter || counter < 5) {
        counter++;
        console.log("counter >> ", counter)
        setTimeout(() => {
          downloadQuizCertificate({ ...params, counter });
        }, 2000);
      }
    }
  }

  const getCategoryActivity = async () => {
    const resultAction = await dispatch(fetchCateoryList({ competition_id: eventDetails?.event_id, page_number: 0, page_size: 100 })).unwrap();
    if (resultAction.status) {
      const categoryId = resultAction?.data?.[0]?.category_id
      const age = getAgeByDOB(kid?.date_of_birth)
      const response = await dispatch(fetchActivities({ category_id: categoryId, competition_id: eventDetails?.event_id, min_age: age, max_age: age })).unwrap();
      setActivityId(response?.data?.[0].id)
    }
  }

  useEffect(() => {
    if (activityId)
      dispatch(fetchHistory({ kidId, activity_id: activityId, competition_id: eventDetails?.event_id }))
  }, [activityId]);

  useEffect(() => {
    if (quizData?.acm_quiz_id && !quizData?.attempt_complete_status && !questionData?.question_id)
      navigate(`/quiz/start`, { state: { kidId, eventId: eventDetails?.event_id } });
  }, [quizData])

  const startTest = () => {
    if (quizProctorData?.is_proctoring) {
      navigate(`/quiz/camera`, {
        state: {
          kidId,
          acm_quiz_id: quizHistory?.[0]?.acm_quiz_id,
          eventId: eventDetails?.event_id
        }
      });
    } else {
      Confirm.show(
        'KidEx - Quiz',
        `You have ${Math.floor(quizHistory?.[0]?.quiz_time / 60)} mins to complete the quiz. Good luck!`,
        'Proceed',
        'Cancel',
        async function okCb() {
          const resultAction = await dispatch(startQuiz({ kidId, acm_quiz_id: quizHistory?.[0]?.acm_quiz_id })).unwrap()
          if (resultAction?.status) {
            dispatch(setQuizData(resultAction?.data))
          } else if (resultAction?.message == "Oops! Time over for the quiz" && quizHistory?.[0]?.incomplete_attempt_id) {
            dispatch(completeQuiz({ attempt_id: quizHistory?.[0]?.incomplete_attempt_id }));
          } else if (resultAction?.message) {
            dispatch(setQuizData({ message: resultAction?.message }))
            Notify.warning(resultAction?.message);
          } else {
            Notify.warning("something went wrong");
          }
          const data = {
            summary_button: resultAction?.data?.summary_button,
            attempt_complete_status: resultAction?.data?.attempt_complete_status,
            show_complete_button: false,
            show_retake_button: false,
          }
          dispatch(setLocalData(data));
        },
        function cancelCb() { },
        {
          className: 'notiflix-confirm',
          width: '320px',
          borderRadius: '8px',
          titleColor: "#9E54E8",
          okButtonBackground: "#9E54E8",
        },
      );
    }
  }

  const fileHandler = async (event) => {
    const { files } = event.target;
    if (files?.length) {
      setPreview({ src: URL.createObjectURL(files[0]), alt: files[0].name });
      const actionResult = await dispatch(createPresignedURL({ file_type: "Image", upload_type: "user" })).unwrap()
      const uploadResponse = await dispatch(CloudFileUpload({ url: actionResult.url, file: files[0] })).unwrap();
      const imageUrl = uploadResponse?.url?.split("?")?.[0]
      await dispatch(updateKid({ kid_id: kid?.unique_id, profileUrl: imageUrl })).unwrap();
      dispatch(fetchKidsList());
    }
  };

  const viewCertificate = () => {
    window.open(certifcate, "_blank");
  }

  return (
    <Base>
      <div className="container basic-profile-details py-5">
        <div className="row mb-3 mb-md-5">
          <div className="col-5 col-md-6 d-flex justify-content-md-end pe-md-5">
            <div className="ict-img-upload">
              <img src={isString(kid?.profileUrl) ? kid?.profileUrl : `${MEDIAPATH}/skin/images/profile/profile.jpeg`} className="ict-user-img" />
              <div className="upload-btn">
                <img src={`${BASEPATH}/images/ict-quiz/camera-white-bold.svg`} />
                <input className="cursor-pointer" id="file-input" accept="image/*" type="file" onChange={fileHandler} />
              </div>
            </div>
          </div>
          <div className="col-7 col-md-6 d-flex align-items-center ps-md-5">
            <div className="basic-profile-details-content">
              <h2 className="basic-profile-details-title">{kid?.name}</h2>
              <p>Class : {kid?.kidClass ?? "NA"}</p>
              <p>Section : {kid?.kidSection ?? "NA"}</p>
              <p>Roll No : {kid?.roll_no ?? "NA"}</p>
              <p>{kid?.school}</p>
            </div>
          </div>
        </div>
        {quizHistory?.[0]?.quiz_attempts?.length == 0 &&
          <div className="row py-3 py-md-5 quicksand">
            <div className="col-4">
              <div className="profile-icon-content">
                <img src={`${BASEPATH}/images/ict-quiz/edit-yellow.svg`} />
                <span>
                  Number of Attempts
                  <b>1</b>
                </span>
              </div>
            </div>
            <div className="col-4">
              <div className="profile-icon-content">
                <img src={`${BASEPATH}/images/ict-quiz/time-yellow.svg`} />
                <span>
                  Max Time
                  <b>30 mins</b>
                </span>
              </div>
            </div>
            <div className="col-4">
              <div className="profile-icon-content">
                <img src={`${BASEPATH}/images/ict-quiz/number-yellow.svg`} />
                <span>
                  Number of Questions
                  <b>25</b>
                </span>
              </div>
            </div>
          </div>
        }
        {quizHistory?.[0]?.quiz_attempts?.length > 0 ?
          <div className="guidelines-sec ">
            <h2 className="guidelines-title quicksand">Some Important Updates</h2>
            <ul className="guidelines-list quicksand five-col-list-grid">
              <li><span>1</span><b>The zonal round details will be announced by August 15th. Stay tuned for further instructions.</b></li>
              <li><span>2</span><b>To access your report card & leaderboard, kindly click on the buttons provided below.</b></li>
              <li><span>3</span><b>Your report card will also be delivered to your inbox within the next 48 hours using the email address you provided during registration.</b></li>
              <li><span>4</span><b>You can also retrieve your report card by logging into your account anytime.</b></li>
              <li><span>5</span><b>Your class teacher will also have access to your report card.</b></li>
            </ul>
          </div>
          : <div className="guidelines-sec ">
            <h2 className="guidelines-title quicksand">Guidelines</h2>
            <ul className="guidelines-list quicksand">
              <li><span>1</span><b>You can take the quiz in full screen mode only</b></li>
              <li><span>2</span><b>You may be required to submit a photo of yourself for verification purposes</b></li>
              <li><span>3</span><b>You will not be able to change/toggle screens without completing quiz</b></li>
              <li><span>4</span><b>You have to give camera & microphone permissions to enable video proctoring, as applicable</b></li>
            </ul>
          </div>
        }
        {quizHistory?.[0]?.quiz_attempts?.length > 0 ?
          <div class="static-footer">
            <div class="text-danger d-flex py-4 justify-content-between congrats-btn-ftr flex-wrap">
              <Link to="/participant" class="quiz-btn bg-transparent white-border-btn me-2">Home</Link>
              <button class="quiz-btn bg-transparent white-border-btn me-2">Leaderboard</button>
              <Link to="/reportcard" state={{ kidId, acm_quiz_id: quizHistory?.[0]?.acm_quiz_id, attempt_id: quizHistory?.[0]?.quiz_attempts?.[0]?.attempt_id }} class="quiz-btn bg-transparent white-border-btn me-2">Report Card</Link>
              {certifcate != "" && <button onClick={viewCertificate} class="quiz-btn bg-transparent white-border-btn pc-btn" disabled={certifcate === ""}>Participation Certificate</button>}
            </div>
          </div>
          : <div className="text-center d-flex justify-content-between pt-5">
            <Link to="/participant" className="site-btn border2x white-border-btn bg-transparent" type="button">Home</Link>
            <button onClick={startTest} className="site-btn" type="button">Start Quiz</button>
          </div>
        }
      </div>
    </Base>
  )
}

export default Dashboard