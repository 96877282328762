import React, { Fragment, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import "./style.css";
import PopUp from '../../components/PopUp';
import { BASEPATH } from '../../services/config';
import Base from '../../components/Layout/Base';

const FullScreenPermission = () => {

  const navigate = useNavigate();
  const location = useLocation();

  const eventId = location.state?.eventId;
  const kidId = location?.state?.kidId;
  const acm_quiz_id = location?.state?.acm_quiz_id;

  const [showModal, setShowModal] = useState(false);

  const getFullScreenPermission = () => {

    if (window.document.body.requestFullscreen) {
      window.document.body.requestFullscreen();
    } else if (window.document.body.mozRequestFullScreen) { /* Firefox */
      window.document.body.mozRequestFullScreen();
    } else if (window.document.body.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
      window.document.body.webkitRequestFullscreen();
    } else if (window.document.body.msRequestFullscreen) { /* IE/Edge */
      window.document.body.msRequestFullscreen();
    }
    navigate(`/quiz/capture`, {
      state: { kidId, acm_quiz_id, eventId },
      replace: true
    });
  }

  useEffect(() => {
    document.body.classList.add('ict-form-detail-theme');
    return () => {
      document.body.classList.remove('ict-form-detail-theme');
    }
  }, []);

  return (
    <Fragment>
      <div className="quiz-name-page ict-quiz pt-2 pt-md-5  mt-3 mt-md-0">
        <div className='container'>
          <div className='quiz-row bg-transparent text-center mb-5 py-4 py-md-5'>
            <div className='permission-content'>
              <div className="icon-circle">
                <img src={`${BASEPATH}/images/quiz/microphone-white.svg`} />
                <span><img src={`${BASEPATH}/images/quiz/required-white.svg`} /> </span>
              </div>
              <h2 className='Quicksand text-white'>Fullscreen mode Required</h2>
              <h4 className='Quicksand text-white'>Your data is absolutely safe with us</h4>
              <div className='quiz-control-btn white-theme-btn permi-btn flex-column justify-content-center align-items-center pt-3'>
                <button className='quiz-btn mb-3 px-3 w-280 mw-230 full-screen-btn' onClick={getFullScreenPermission}>Enable fullscreen mode</button>
                <button onClick={() => { setShowModal(true) }} className='quiz-btn full-screen-btn quiz-border-btn px-3 w-280 mw-230'>Not Now</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopUp show={showModal} handleClose={() => setShowModal(false)}>
        <div className='modal-camera-perm'>
          <img src={`${BASEPATH}/images/quiz/retry.svg`} />
          <h2>To participate in the Quiz you need to allow the fullscreen mode</h2>
          <button type="button" onClick={() => setShowModal(false)} className='quiz-btn mb-3 px-5 w-280 mw-230'>Retry</button>
        </div>
      </PopUp>
    </Fragment>
  )
}

export default FullScreenPermission